<template>
    <div>
        <div class="demo-spin-article">
            <div class="row">
                <div class="col-md-12">
                    <form class="form-horizontal form-horizontal-text-right">
                        <div class="row">
                            <div class="col-md-12">
                                <disbursement-profile
                                    v-model="model"
                                    :validation="errors"
                                    ref="disbursementProfile"
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <Spin size="large" fix v-if="loading"> </Spin>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { Errors } from "form-backend-validation";
import moment from "moment";
import { isEmpty } from "lodash";
import disbursementProfile from "./components/disbursement-profile.vue";

export default {
    name: "loanDisbursementCreate",
    props: ["value"],
    components: {
        disbursementProfile
    },
    data() {
        return {
            loading: false,
            waiting: false,
            waiting_new: false,
            save_previewing: false,
            continueNextStep: false,
            errors: new Errors(),
            model: {
                loan_id: null,
                disbursement_date: moment().format("DD-MM-YYYY"),
                customer_id: null,
                voucher_number: null,
                loan_type_id: null,
                currency_id: 1,
                loan_amount: null,
                interest_rate: null,
                repayment_method_id: null,
                payment_interval: 1,
                first_installment_date: moment()
                    .add(1, "days")
                    .format("DD-MM-YYYY"),
                installment_type_id: null,
                number_of_cycle: null,
                is_skip_saturday: false,
                is_skip_sunday: false,
                amount_per_cycle: null,
                allow_pay_off_on_cycle: null,
                co_id: null,
                semi_baloon_effective_cycle: null,
                remarks: null,
                input_source: "W",
                asset_down_payment_id: null,
                request_id: null
            }
        };
    },
    computed: {
        ...mapState("creditOperation/loanDisbursement", [
            "edit_data",
            "loanTypes"
        ]),
        isUpdate() {
            if (!isEmpty(this.edit_data)) {
                return true;
            }
            return false;
        }
    },
    methods: {
        ...mapActions("creditOperation/loanDisbursement", [
            "getLoanType",
            "getCurrency",
            "getRepaymentMethod",
            "find",
            "getEmployees",
            "getCustomer"
        ]),
        async fetchResource() {
            this.loading = true;
            await this.getLoanType();
            await this.getCurrency();
            await this.getRepaymentMethod();
            await this.$refs.disbursementProfile.loadEmployee();
            await this.$refs.disbursementProfile.loadCustomer();
            this.setCurrencyToDefault();
            await this.setEditData();
            this.loading = false;
            return true;
        },
        setCurrencyToDefault() {
            this.model.currency_id = this.$store.state.authUser.sys_setting[0].default_currency_id;
        },
        async onSave() {
            this.continueNextStep = false;
            this.errors = new Errors();
            this.waiting = true;
            await this.$store
                .dispatch("creditOperation/loanDisbursement/store", this.model)
                .then(async response => {
                    if (response.message_id == 0) {
                        await this.$store
                            .dispatch(
                                "creditOperation/loanDisbursement/find",
                                response.resource_id
                            )
                            .then(() => {
                                this.continueNextStep = true;
                            })
                            .catch(error => {
                                this.errors = new Errors(error.errors);
                                this.notice({
                                    type: "error",
                                    text: error.message
                                });
                            });
                    } else {
                        this.notice({
                            type: "warning",
                            text: response.message
                        });
                    }
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                    this.continueNextStep = false;
                })
                .finally(() => {
                    this.waiting = false;
                });
            return this.continueNextStep;
        },
        clearInput() {
            this.errors = new Errors();
            (this.model.disbursement_date = moment().format("DD-MM-YYYY")),
                (this.model.loan_id = null),
                (this.model.customer_id = null),
                (this.model.voucher_number = null),
                (this.model.loan_type_id = null),
                (this.model.currency_id = 1),
                (this.model.loan_amount = null),
                (this.model.interest_rate = null),
                (this.model.repayment_method_id = null),
                (this.model.payment_interval = 1),
                (this.model.first_installment_date = moment()
                    .add(1, "days")
                    .format("DD-MM-YYYY")),
                (this.model.installment_type_id = null),
                (this.model.number_of_cycle = null),
                (this.model.is_skip_saturday = false),
                (this.model.is_skip_sunday = false),
                (this.model.amount_per_cycle = null),
                (this.model.allow_pay_off_on_cycle = null),
                (this.model.co_id = null),
                (this.model.semi_baloon_effective_cycle = 0),
                (this.model.remarks = null),
                (this.model.request_id = null);
        },
        async setEditData() {
            if (!isEmpty(this.edit_data)) {
                await this.getEmployees({
                    search: this.edit_data.co
                        ? this.edit_data.co.employee_name_en
                        : null
                });
                await this.getCustomer({
                    search: this.edit_data.customer
                        ? this.edit_data.customer.customer_name_en
                        : null
                });
                let loanType = this.loanTypes.find(
                    l => l.loan_type_id == this.edit_data.loan_type_id
                );
                this.model.customer_id = this.edit_data.customer_id;
                this.model.loan_type_id = this.edit_data.loan_type_id;
                this.model.currency_id = this.edit_data.currency_id;
                this.model.loan_amount = this.edit_data.app_amount;
                this.model.interest_rate = this.edit_data.app_interest_rate;
                this.model.installment_type_id = this.edit_data.app_installment_type_id;
                this.model.number_of_cycle = this.edit_data.app_loan_duration;
                this.model.is_skip_saturday = loanType.is_skip_saturday;
                this.model.is_skip_sunday = loanType.is_skip_sunday;
                this.model.co_id = this.edit_data.emp_co_id;
                this.model.request_id = this.edit_data.request_id;
                this.model.payment_interval = this.edit_data.interval_2_week
                    ? 2
                    : 1;
                this.model.disbursement_date = this.edit_data
                    .app_disbursement_date
                    ? this.edit_data.app_disbursement_date
                    : moment().format("DD-MM-YYYY");
                this.model.first_installment_date = this.edit_data
                    .app_first_installment_date
                    ? this.edit_data.app_first_installment_date
                    : moment()
                          .add(1, "months")
                          .format("DD-MM-YYYY");
            }
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "LOAN DISBURSEMENT",
                desc: not.text
            });
        }
    }
};
</script>
