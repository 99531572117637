<template>
    <div>
        <div>
            <panel variant="default">
                <template slot="header">
                    <h4 class="panel-title">
                        {{ $t("loanDisbursement.repaymentSchedule") }}
                    </h4>
                    <ts-button
                        :disabled="!isRepaymentSchedules"
                        color="warning"
                        outline
                        class="btn-xs tw-mr-2"
                        @click="customerContract()"
                        ><i class="far fa-file-pdf"></i>
                        {{ $t("customerLoanRequest.loanAgreement") }}</ts-button
                    >
                    <ts-button
                        :disabled="!isRepaymentSchedules"
                        color="primary"
                        outline
                        class="btn-xs tw-mr-2"
                        @click="previewPdf(2)"
                        ><i class="far fa-file-pdf"></i>
                        {{ $t("loanDisbursement.customerSchedule") }}</ts-button
                    >
                    <ts-button
                        :disabled="!isRepaymentSchedules"
                        color="danger"
                        outline
                        class="btn-xs tw-mr-2"
                        @click="previewPdf(1)"
                        ><i class="far fa-file-pdf"></i>
                        {{ $t("previewPdf") }}</ts-button
                    >
                    <ts-button
                        :disabled="!isRepaymentSchedules"
                        color="success"
                        outline
                        class="btn-xs tw-mr-2"
                        @click="onExportExcel"
                    >
                        <i
                            class="fas fa-circle-notch fa-spin"
                            v-if="exporting"
                        ></i>
                        <i class="far fa-file-excel" v-else></i>
                        {{ $t("exportExcel") }}</ts-button
                    >
                </template>
                <div style="min-height: 200px">
                    <div class="overflow-auto">
                        <div class="demo-spin-article">
                            <div
                                class="table-ui-detail"
                                style="max-height:34vh"
                            >
                                <table>
                                    <thead>
                                        <tr>
                                            <th width="15%">
                                                {{
                                                    $t(
                                                        "loanDisbursement.paymentDate"
                                                    )
                                                }}
                                            </th>
                                            <th
                                                width="15%"
                                                class="tw-text-center"
                                            >
                                                {{
                                                    $t(
                                                        "loanDisbursement.principle"
                                                    )
                                                }}
                                            </th>
                                            <th
                                                class="tw-text-center"
                                                width="15%"
                                            >
                                                {{
                                                    $t(
                                                        "loanDisbursement.interest"
                                                    )
                                                }}
                                            </th>
                                            <th
                                                class="tw-text-center"
                                                width="15%"
                                            >
                                                {{
                                                    $t(
                                                        "loanDisbursement.monthlyFee"
                                                    )
                                                }}
                                            </th>
                                            <th
                                                class="tw-text-center"
                                                width="15%"
                                            >
                                                {{
                                                    $t(
                                                        "loanDisbursement.amountRepay"
                                                    )
                                                }}
                                            </th>
                                            <th
                                                class="tw-text-center"
                                                width="15%"
                                            >
                                                {{
                                                    $t(
                                                        "loanDisbursement.balance"
                                                    )
                                                }}
                                            </th>
                                            <th width="15%" class="text-center">
                                                {{
                                                    $t(
                                                        "loanDisbursement.payType"
                                                    )
                                                }}
                                            </th>
                                            <th
                                                class="tw-text-center"
                                                width="15%"
                                            >
                                                {{
                                                    $t(
                                                        'loanDisbursement.allowPayOffOnCycle'
                                                    )
                                                }}
                                            </th>
                                            <th class="text-center" width="10%">
                                                {{
                                                    $t("loanDisbursement.paid")
                                                }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="isRepaymentSchedules">
                                        <tr
                                            v-for="(row,
                                            index) in edit_data.repayment_schedules"
                                            :key="index"
                                        >
                                            <td class="tw-whitespace-nowrap">
                                                {{ row.cycle_payment_date }}
                                            </td>
                                            <td class="tw-whitespace-nowrap">
                                                <div
                                                    class="tw-flex tw-justify-between"
                                                >
                                                    <span class="text-left">{{
                                                        signBycurrencyId(
                                                            edit_data.currency_id
                                                        )
                                                    }}</span>
                                                    <span class="text-right">{{
                                                        formatNumber(
                                                            row.principal,
                                                            edit_data.currency_id
                                                        )
                                                    }}</span>
                                                </div>
                                            </td>
                                            <td class="tw-whitespace-nowrap">
                                                <div
                                                    class="tw-flex tw-justify-between"
                                                >
                                                    <span class="text-left">{{
                                                        signBycurrencyId(
                                                            edit_data.currency_id
                                                        )
                                                    }}</span>
                                                    <span class="text-right">{{
                                                        formatNumber(
                                                            row.interest,
                                                            edit_data.currency_id
                                                        )
                                                    }}</span>
                                                </div>
                                            </td>
                                            <td class="tw-whitespace-nowrap">
                                                <div
                                                    class="tw-flex tw-justify-between"
                                                >
                                                    <span class="text-left">{{
                                                        signBycurrencyId(
                                                            edit_data.currency_id
                                                        )
                                                    }}</span>
                                                    <span class="text-right">{{
                                                        formatNumber(
                                                            row.monthly_fee,
                                                            edit_data.currency_id
                                                        )
                                                    }}</span>
                                                </div>
                                            </td>
                                            <td class="tw-whitespace-nowrap">
                                                <div
                                                    class="tw-flex tw-justify-between"
                                                >
                                                    <span class="text-left">{{
                                                        signBycurrencyId(
                                                            edit_data.currency_id
                                                        )
                                                    }}</span>
                                                    <span class="text-right">{{
                                                        formatNumber(
                                                            row.total_amount,
                                                            edit_data.currency_id
                                                        )
                                                    }}</span>
                                                </div>
                                            </td>
                                            <td class="tw-whitespace-nowrap">
                                                <div
                                                    class="tw-flex tw-justify-between"
                                                >
                                                    <span class="text-left">{{
                                                        signBycurrencyId(
                                                            edit_data.currency_id
                                                        )
                                                    }}</span>
                                                    <span class="text-right">{{
                                                        formatNumber(
                                                            row.ending_balance,
                                                            edit_data.currency_id
                                                        )
                                                    }}</span>
                                                </div>
                                            </td>
                                            <td
                                                class="tw-whitespace-nowrap tw-text-center"
                                            >
                                                {{
                                                    row.installment_type == 1
                                                        ? "Principal/Interest"
                                                        : "Interest"
                                                }}
                                            </td>
                                            <td
                                                class="text-center tw-whitespace-nowrap tw-justify-items-center tw-items-center"
                                            >
                                                <Checkbox
                                                    v-model="row.is_allow_payoff"
                                                    :disabled="true"
                                                ></Checkbox>
                                            </td>
                                            <td
                                                class="text-center tw-whitespace-nowrap tw-justify-items-center tw-items-center"
                                            >
                                                <Checkbox
                                                    v-model="row.is_paid"
                                                    :disabled="true"
                                                ></Checkbox>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <Spin size="large" fix v-if="scheduleLoading">
                        </Spin>
                    </div>
                </div>
            </panel>
        </div>
        <pdf-preview
            v-model="pdf_view"
            :id-lists="loanIdLists"
            :type="type"
            @cancel="value => (pdf_view = value)"
        />
        <contract-pdf-preview
            v-model="contract_pdf_view"
            :request-id="request_id"
            @cancel="value => (contract_pdf_view = value)"
        />
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { isEmpty } from "lodash";
import PdfPreview from "./pdf-preview";
import ContractPdfPreview from "./contract-pdf-preview";
import { trim } from "lodash";
import * as FileDownload from "downloadjs";

export default {
    name: "repaymentSchedule",
    components: {
        PdfPreview,
        ContractPdfPreview
    },
    data() {
        return {
            scheduleLoading: false,
            pdf_view: false,
            contract_pdf_view: false,
            exporting: false,
            type: 1,
            request_id: null
        };
    },
    computed: {
        ...mapState("creditOperation/loanDisbursement", ["edit_data"]),
        ...mapGetters(["formatNumber", "signBycurrencyId"]),
        loanIdLists() {
            if (!isEmpty(this.edit_data)) {
                return [this.edit_data.loan_id];
            }
            return [];
        },
        isRepaymentSchedules() {
            if (!isEmpty(this.edit_data)) {
                return true;
            }
            return false;
        }
    },
    methods: {
        previewPdf(type) {
            this.pdf_view = true;
            this.type = type;
        },
        customerContract() {
            this.contract_pdf_view = true;
            this.request_id = [this.$route.params.id]
        },
        onExportExcel() {
            this.exporting = true;
            this.$store
                .dispatch("creditOperation/loanDisbursement/exportExcel", {
                    loan_id_lists: this.loanIdLists
                })
                .then(response => {
                    let filename = trim(
                        response.headers["content-disposition"]
                            .substring(
                                response.headers["content-disposition"].indexOf(
                                    "filename"
                                )
                            )
                            .replace("filename=", ""),
                        '"'
                    );
                    FileDownload(response.data, filename);
                })
                .catch(err => {
                    let error = JSON.parse(
                        String.fromCharCode.apply(null, new Uint8Array(err))
                    );
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.exporting = false;
                });
        }
    }
};
</script>
<style scoped>
.table-ui-detail table td {
    border: 1px dashed #e9e9e9 !important;
    padding: 3px 9px;
}
</style>
